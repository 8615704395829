@import 'src/styles/variables';

.loadMore {
  width: 100%;
  text-align: center;
  margin-bottom: $space-m;

  .btn {
    padding-left: $space-l;
    padding-right: $space-l;
    font-weight: 600;
    position: relative;
  }

  .ldsDualRing {
    display: none;
  }

  &.isLoading {
    .ldsDualRing {
      display: block;
      position: absolute;
      right: 10px;
      top: 8px;
    }
  }
}
